import React from "react";
import { LightSection, YellowSection } from "../../common/section";
import { Link } from "gatsby";

const Contact = () => {
  return (
    <div>
      <LightSection>
        <Link
          to='/'
          className='w-full text-brand h-16 z-50 -mt-24 md:-mt-28 mb-10 py-4 text-center font-bold hover:bg-brand-100 transition flex justify-center content-center'
          activeClassName='active'
        >
          <span className='mr-2 inline-block'>←</span>Zurück zur Startseite
        </Link>
        <h2 className='text-display-md lg:text-display-lg text-left uppercase font-ultra text-yellow'>
          Impressum
        </h2>
        <h3 className='mb-16 text-body-lg lg:text-display-xs text-left uppercase text-black'>
          Angaben gemäSS § 5 Telemediengesetz
        </h3>
        <address className='mb-16 not-italic'>
          <p>
            FYZIO Therapie@Home GmbH
            <br />
            Tullastraße 27
            <br /> 77933 Lahr
            <br />
          </p>
        </address>
        <div className='grid grid-cols-10'>
          <div className='col-span-10 lg:col-span-4 border-l-4 pl-4 border-yellow'>
            <p>
              <strong>Vertreten durch:</strong>
              <br />
              Geschäftsführer <br />
              Maik Fischer (CEO)
            </p>
            <p>
              <strong>Kontakt:</strong>
              <br />
              Telefon: 07821 / 588 355 0<br />
              Telefax: 07821 / 588 355 1<br />
              E-Mail: kontakt@fyzio.de
            </p>
            <p>
              <strong>Steuernummer:</strong> <br />
              FA Offenburg 14017/70207
            </p>
          </div>
          <div className='col-span-10 lg:col-span-6 border-l-4 pl-4 border-yellow'>
            <p>
              <strong>Registereintrag</strong>
              <br />
              Registernummer: HRB 727113
              <br />
              Registergericht: AG Freiburg
            </p>
            <p>
              <strong> Umsatzsteuer:</strong>
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz
              <br />
              DE 355347251
            </p>
            <br />
            <p>
              <strong>Verantwortlich</strong> für den Inhalt nach § 55 Abs. 2
              RStV:
              <br />
              Maik Fischer
            </p>
          </div>
        </div>
      </LightSection>
      <YellowSection>
        <h3 className='bold text-display-xs text-white'>Streitschlichtung</h3>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht
          bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h3 className='bold text-display-xs text-white'>Haftung für Inhalte</h3>
        <p>
          Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
          diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
          bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet,
          übermittelte oder gespeicherte fremde Informationen zu überwachen oder
          nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
          hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung
          von Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>
        <h3 className='bold text-display-xs text-white'>Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <h3 className='bold text-display-xs text-white'>Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen. Die Berufsaussicht
          der Heilmittelerbringer wird durch die jeweils zuständigen
          Gesundheitsämter am Sitz der jeweiligen Zweigpraxen ausgeübt.
        </p>
      </YellowSection>
    </div>
  );
};
export default Contact;
